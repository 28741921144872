<template>
  <transition name="fade">
    <div class="avatar-editor" v-if="avatarsData && isOpen">
      <div class="avatar-editor__colors">
        <div class="avatar-editor-inner">
          <div
            class="avatar-outer"
            :style="`background-color: hsla(${form.color}, 100%, 50%, 0.6)`"
          >
            <img v-if="form.url" class="avatar" :src="form.url" alt="avatar" />

            <SvgSprite
              v-else
              class="avatar--default"
              symbol="ui-default"
              size="16"
            />
          </div>

          <div class="avatar-editor__color">
            <h3>Choisis ta couleur</h3>
            <input
              class="colorpicker"
              type="range"
              step="1"
              min="1"
              max="360"
              v-model="form.color"
            />
          </div>
        </div>
      </div>
      <div class="avatar-editor__totems">
        <div class="avatar-editor-inner">
          <h3>Choisis ton Totem</h3>
          <div class="totem-list">
            <div v-for="(avatar, index) in avatarsData" :key="`totem-${index}`">
              <input
                v-model="form.url"
                :value="avatar"
                type="radio"
                :id="index"
              />
              <label class="totem-outer" :for="index">
                <img class="totem" :src="avatar" :alt="`avatar-${index}`" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { getCurrentInstance, defineComponent, reactive, ref, watch } from 'vue'
// import hsl from 'hsl-to-hex'
import { avatars, profile, save } from '@/guides/endpoints/profile'
import debounce from 'lodash.debounce'

export default defineComponent({
  name: 'AvatarEditor',
  components: {},
  props: {
    data: Object,
    isOpen: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line

  setup(props) {
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const form = reactive({
      color: '',
      url: '',
    })

    const avatarsData = ref()
    const profileData = ref()

    const fetch = () => {
      if (props.isOpen) {
        profile().then(r => {
          // console.log('profile', r)
          form.color = r?.data?.avatar.color
          form.url = r?.data?.avatar.url
        })

        avatars().then(r => {
          console.log('avatars', r)
          // console.log('hsla', hsl(135, 100, 50))
          avatarsData.value = r.data
        })
      }
    }

    watch(() => props.isOpen, fetch)

    const validate = () => {
      if (!form.color || !form.url) {
        return
      }

      save(form).then(() => {
        emitter.emit('updateavatar')
        // setTimeout(() => ctx.emit('update:isOpen', false), 200)
      })
    }

    const debounceValidate = debounce(validate, 200)
    watch(() => form.color, debounceValidate)
    watch(() => form.url, debounceValidate)

    return {
      avatarsData,
      form,
      profileData,
    }
  },
})
</script>

<style lang="scss" scoped>
.avatar-editor {
  position: fixed;
  top: 5.5rem;
  left: 0;
  z-index: 13;
  width: 100%;
  // min-height: 100%;
  background: $athens-gray;

  @include mq($until: s) {
    max-height: calc(100vh - 55px);
    // padding-bottom: $spacing * 4;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  @include mq(s) {
    position: absolute;
    left: 17rem;
    width: 34rem;
    filter: drop-shadow(-25px 25px 54px rgba(204, 217, 228, 0.6));

    &:before {
      content: '\A';
      display: block;
      width: 2rem;
      height: 2rem;
      transform: rotate(45deg);
      background-color: white;
      position: absolute;
      top: 5rem;
      left: -1rem;
      z-index: -1;
    }
  }
}

.avatar-editor__colors {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  background-color: white;
}

.avatar-editor__totems {
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem;

  @include mq(s) {
    padding-bottom: 0;
  }
}

.avatar-outer {
  margin: 0 auto;
  padding: 1.4rem;
  position: relative;
  width: 16rem;
  height: 16rem;
  flex: 0 0 16rem;
  border-radius: 99em;
  background-color: $rock-blue;

  @include mq(s) {
    position: absolute;
    left: -15rem;
    top: 1.2rem;
    width: 11.3rem;
    height: 11.3rem;
  }
}

.avatar {
  width: 100%;
}

.avatar--default {
  @include center-xy;

  width: 100%;
  height: 56.25%;
}

.avatar-editor__color h3 {
  @include mq($until: s) {
    display: none;
  }
}

.totem-list {
  margin: $spacing * 1.5 0 $spacing * 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;

  @include mq(s) {
    grid-column-gap: 34px;
    grid-row-gap: 26px;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0;
    padding-bottom: 30px;
  }
}

.totem-outer {
  @include aspect-ratio(1, 1);
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 99em;
  background-color: white;
  cursor: pointer;
  overflow: hidden;

  img {
    margin: auto;
    width: 80%;
  }
}

input[type='radio'] {
  display: none;

  &:checked + label {
    background-color: $rock-blue;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      height: 25%;
      background: url('~@/assets/images/check.svg');
      background-size: cover;
    }
  }
}

// TEST COLORPICKER
.colorpicker {
  margin: $spacing * 1.5 0;
  display: block;
  height: 1.2rem;
}

input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 3px solid #004079;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-top: -3px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  border: 3px solid #004079;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

// styling for input range track
.colorpicker::-webkit-slider-runnable-track {
  width: 100%;
  height: 1.2rem;
  background: -webkit-linear-gradient(
    left,
    hsla(0, 100%, 50%, 0.6),
    hsla(10, 100%, 50%, 0.6),
    hsla(20, 100%, 50%, 0.6),
    hsla(30, 100%, 50%, 0.6),
    hsla(40, 100%, 50%, 0.6),
    hsla(50, 100%, 50%, 0.6),
    hsla(60, 100%, 50%, 0.6),
    hsla(70, 100%, 50%, 0.6),
    hsla(80, 100%, 50%, 0.6),
    hsla(90, 100%, 50%, 0.6),
    hsla(100, 100%, 50%, 0.6),
    hsla(110, 100%, 50%, 0.6),
    hsla(120, 100%, 50%, 0.6),
    hsla(130, 100%, 50%, 0.6),
    hsla(140, 100%, 50%, 0.6),
    hsla(150, 100%, 50%, 0.6),
    hsla(160, 100%, 50%, 0.6),
    hsla(170, 100%, 50%, 0.6),
    hsla(180, 100%, 50%, 0.6),
    hsla(190, 100%, 50%, 0.6),
    hsla(200, 100%, 50%, 0.6),
    hsla(210, 100%, 50%, 0.6),
    hsla(220, 100%, 50%, 0.6),
    hsla(230, 100%, 50%, 0.6),
    hsla(240, 100%, 50%, 0.6),
    hsla(250, 100%, 50%, 0.6),
    hsla(260, 100%, 50%, 0.6),
    hsla(270, 100%, 50%, 0.6),
    hsla(280, 100%, 50%, 0.6),
    hsla(290, 100%, 50%, 0.6),
    hsla(300, 100%, 50%, 0.6),
    hsla(310, 100%, 50%, 0.6),
    hsla(320, 100%, 50%, 0.6),
    hsla(330, 100%, 50%, 0.6),
    hsla(340, 100%, 50%, 0.6),
    hsla(350, 100%, 50%, 0.6),
    hsla(360, 100%, 50%, 0.6)
  );
}

.colorpicker::-moz-range-track {
  width: 100%;
  height: 1.2rem;
  background: -webkit-linear-gradient(
    left,
    hsla(0, 100%, 50%, 0.6),
    hsla(10, 100%, 50%, 0.6),
    hsla(20, 100%, 50%, 0.6),
    hsla(30, 100%, 50%, 0.6),
    hsla(40, 100%, 50%, 0.6),
    hsla(50, 100%, 50%, 0.6),
    hsla(60, 100%, 50%, 0.6),
    hsla(70, 100%, 50%, 0.6),
    hsla(80, 100%, 50%, 0.6),
    hsla(90, 100%, 50%, 0.6),
    hsla(100, 100%, 50%, 0.6),
    hsla(110, 100%, 50%, 0.6),
    hsla(120, 100%, 50%, 0.6),
    hsla(130, 100%, 50%, 0.6),
    hsla(140, 100%, 50%, 0.6),
    hsla(150, 100%, 50%, 0.6),
    hsla(160, 100%, 50%, 0.6),
    hsla(170, 100%, 50%, 0.6),
    hsla(180, 100%, 50%, 0.6),
    hsla(190, 100%, 50%, 0.6),
    hsla(200, 100%, 50%, 0.6),
    hsla(210, 100%, 50%, 0.6),
    hsla(220, 100%, 50%, 0.6),
    hsla(230, 100%, 50%, 0.6),
    hsla(240, 100%, 50%, 0.6),
    hsla(250, 100%, 50%, 0.6),
    hsla(260, 100%, 50%, 0.6),
    hsla(270, 100%, 50%, 0.6),
    hsla(280, 100%, 50%, 0.6),
    hsla(290, 100%, 50%, 0.6),
    hsla(300, 100%, 50%, 0.6),
    hsla(310, 100%, 50%, 0.6),
    hsla(320, 100%, 50%, 0.6),
    hsla(330, 100%, 50%, 0.6),
    hsla(340, 100%, 50%, 0.6),
    hsla(350, 100%, 50%, 0.6),
    hsla(360, 100%, 50%, 0.6)
  );
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
