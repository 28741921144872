<template>
  <div :class="`tag${modifier ? `--${modifier}` : ''}`">
    <SvgSprite
      v-if="data.icon"
      class="tag__icon"
      :symbol="`icons-${data.icon}`"
      size="16"
    />
    <span>{{ data.label }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tag',
  props: {
    data: Object,
    modifier: String,
  } as any, // eslint-disable-line
})
</script>

<style lang="scss" scoped>
[class*='tag--'],
.tag {
  display: flex;
  align-items: center;
  background-color: $athens-gray;
  color: $regal-blue;
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: 600;
  margin-bottom: $spacing / 2;
  padding: $spacing / 4 $spacing / 2;
  border-radius: 20px;

  @include mq(s) {
    padding: $spacing * 0.3 $spacing / 2;
  }

  &[class*='--alt'] {
    color: $tangerine;
    background-color: $c-white;
  }

  &[class*='--reverse'] {
    color: $tangerine;
    background-color: $athens-gray;
  }
}
.tag__icon {
  margin-right: 0.5rem;
}
</style>
