
import { defineComponent, ref, watch } from 'vue'
import { profile } from '@/guides/endpoints/profile'

import BackBtn from '@/components/BackBtn.vue'
import AvatarEditor from '@/components/profile/AvatarEditor.vue'
import Badge from '@/components/Badge.vue'
import Tag from '@/components/Tag.vue'

export default defineComponent({
  name: 'Profile',
  components: {
    AvatarEditor,
    BackBtn,
    Badge,
    Tag,
  },
  setup() {
    const isVisible = ref(false)
    const profileData = ref()

    profile().then(r => {
      // console.log('profile', r)
      profileData.value = r.data
    })

    const fetch = () => {
      if (isVisible.value === false) {
        console.log('update')
        profile().then(r => {
          profileData.value = r.data
        })
      }
    }

    watch(() => isVisible.value, fetch)

    const showAvatarModal = () => {
      isVisible.value = true
    }

    const hideAvatarModal = () => {
      isVisible.value = false
    }

    return {
      hideAvatarModal,
      isVisible,
      showAvatarModal,
      profileData,
    }
  },
})
