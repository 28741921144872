
import { getCurrentInstance, defineComponent, reactive, ref, watch } from 'vue'
// import hsl from 'hsl-to-hex'
import { avatars, profile, save } from '@/guides/endpoints/profile'
import debounce from 'lodash.debounce'

export default defineComponent({
  name: 'AvatarEditor',
  components: {},
  props: {
    data: Object,
    isOpen: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line

  setup(props) {
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const form = reactive({
      color: '',
      url: '',
    })

    const avatarsData = ref()
    const profileData = ref()

    const fetch = () => {
      if (props.isOpen) {
        profile().then(r => {
          // console.log('profile', r)
          form.color = r?.data?.avatar.color
          form.url = r?.data?.avatar.url
        })

        avatars().then(r => {
          console.log('avatars', r)
          // console.log('hsla', hsl(135, 100, 50))
          avatarsData.value = r.data
        })
      }
    }

    watch(() => props.isOpen, fetch)

    const validate = () => {
      if (!form.color || !form.url) {
        return
      }

      save(form).then(() => {
        emitter.emit('updateavatar')
        // setTimeout(() => ctx.emit('update:isOpen', false), 200)
      })
    }

    const debounceValidate = debounce(validate, 200)
    watch(() => form.color, debounceValidate)
    watch(() => form.url, debounceValidate)

    return {
      avatarsData,
      form,
      profileData,
    }
  },
})
