<template>
  <div class="profile" v-if="profileData">
    <BackBtn class="profile__back" />
    <div class="profile-card">
      <div class="profile-card-inner wrapper">
        <div
          v-if="profileData.avatar"
          class="profile-card__avatar-outer"
          :class="{ invisible: isVisible }"
          :style="
            `background-color: hsla(${profileData.avatar.color}, 100%, 50%, 0.6)`
          "
          @click.prevent="showAvatarModal"
        >
          <img
            v-if="profileData.avatar.url"
            class="profile-card__avatar"
            :src="profileData.avatar.url"
            :alt="profileData.firstname"
          />

          <SvgSprite
            v-else
            class="profile-card__avatar--default"
            symbol="ui-default"
            size="16"
          />

          <div class="profile-card__avatar__btn">
            <SvgSprite class="edit__icon" symbol="ui-avatar" size="14" />
          </div>
        </div>

        <div class="profile-card__content">
          <h2 class="profile-card__user">
            {{ profileData.firstname }} {{ profileData.lastname }}
          </h2>
          <p class="profile-card__status">{{ profileData.role }}</p>
          <div class="profile-card__tags">
            <Tag
              v-if="profileData.totem"
              class="profile-card__tags__tag"
              :data="{ icon: 'totem', label: profileData.totem }"
            />

            <Tag
              v-if="profileData.quali"
              class="profile-card__tags__tag"
              :data="{ icon: 'quali', label: profileData.quali }"
            />

            <Tag
              class="profile-card__tags__tag"
              v-if="profileData.branch"
              :data="{
                icon: profileData.branch
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''),
                label: profileData.branch,
              }"
            />

            <Tag
              v-if="profileData.unit"
              class="profile-card__tags__tag"
              :data="{ icon: 'unit', label: profileData.unit }"
            />
          </div>
        </div>

        <AvatarEditor :data="profileData.avatar" v-model:isOpen="isVisible" />

        <div
          class="outside"
          v-show="isVisible"
          @click.prevent="hideAvatarModal"
        ></div>
      </div>
      <a
        href="https://guides-prod.audaxis.com/compierezk/"
        target="_blank"
        class="profile-card__edit"
      >
        Éditer sur SCRIBe
        <button>
          <SvgSprite symbol="ui-edit" size="16" />
        </button>
      </a>
    </div>
    <div class="profile-inner wrapper">
      <h3>Mes badges</h3>
      <div class="badges-list">
        <Badge
          :data="badge"
          v-for="badge in profileData.badges"
          :key="`badge-${badge.label}`"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { profile } from '@/guides/endpoints/profile'

import BackBtn from '@/components/BackBtn.vue'
import AvatarEditor from '@/components/profile/AvatarEditor.vue'
import Badge from '@/components/Badge.vue'
import Tag from '@/components/Tag.vue'

export default defineComponent({
  name: 'Profile',
  components: {
    AvatarEditor,
    BackBtn,
    Badge,
    Tag,
  },
  setup() {
    const isVisible = ref(false)
    const profileData = ref()

    profile().then(r => {
      // console.log('profile', r)
      profileData.value = r.data
    })

    const fetch = () => {
      if (isVisible.value === false) {
        console.log('update')
        profile().then(r => {
          profileData.value = r.data
        })
      }
    }

    watch(() => isVisible.value, fetch)

    const showAvatarModal = () => {
      isVisible.value = true
    }

    const hideAvatarModal = () => {
      isVisible.value = false
    }

    return {
      hideAvatarModal,
      isVisible,
      showAvatarModal,
      profileData,
    }
  },
})
</script>

<style lang="scss" scoped>
.profile {
  position: relative;
}

.profile-card {
  position: relative;
  background-color: white;
}
.profile-card-inner {
  display: flex;
  align-items: center;
  padding-top: $spacing * 3;
  padding-bottom: $spacing * 3;
  position: relative;
}
.profile-card__avatar-outer {
  margin-right: $spacing * 1.25;
  padding: 1.4rem;
  position: relative;
  width: 10rem;
  height: 10rem;
  flex: 0 0 10rem;
  border-radius: 99em;
  background-color: $rock-blue;
  cursor: pointer;

  @include mq(s) {
    margin-right: $spacing * 2;
    width: 11.3rem;
    height: 11.3rem;
    flex: 0 0 11.3rem;

    &.invisible {
      background-color: transparent !important;
    }
  }
}

.profile-card__avatar {
  @include mq(s) {
    .invisible & {
      display: none;
    }
  }
}

.profile-card__avatar--default {
  @include center-xy;

  width: 100%;
  height: 56.25%;

  @include mq(s) {
    .invisible & {
      display: none;
    }
  }
}

.profile-card__avatar__btn {
  position: absolute;
  bottom: -0.3rem;
  right: 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 99em;
  display: flex;

  @include mq(s) {
    .invisible & {
      display: none;
    }
  }
}
.profile-card__user {
  color: $regal-blue;
  font-size: 2.4rem;
  text-transform: uppercase;
  @include mq(s) {
    font-size: 3.6rem;
  }
}
.profile-card__status {
  color: $hippie-blue;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
}
.profile-card__tags {
  margin-top: $spacing * 0.8;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.profile-card__tags__tag {
  margin-right: $spacing / 4;
  line-height: 1;
}

.profile-card__edit {
  position: absolute;
  bottom: 1.6rem;
  right: 2.6rem;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: $regal-blue;

  button {
    width: 2.8rem;
    height: 2.8rem;
    background-color: $botticelli;
    padding: 0.5rem;
    border-radius: 99em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    border: 0;
    margin-left: 10px;
  }

  &:hover {
    button {
      background-color: $regal-blue;
    }
  }
}

.profile-inner {
  padding-top: $spacing * 3;
  padding-bottom: $spacing * 3;
  position: relative;
}

.badges-list {
  margin-top: $spacing * 0.75;
  display: grid;
  max-width: 1200px;
  gap: 15px;

  @include mq(m) {
    gap: 30px;
  }

  @include mq(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.outside {
  @include get-all-space;
  position: fixed;
  z-index: 12;
  background: transparent;
  cursor: pointer;
}
</style>
