
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tag',
  props: {
    data: Object,
    modifier: String,
  } as any, // eslint-disable-line
})
